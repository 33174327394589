import TooltipWarning, { TooltipWarningProps } from 'components/tooltip-warning';
import { HelpCircleOutlineIcon } from 'components/icons';

export interface QuestionMarkProps extends TooltipWarningProps {
  textColorQuestionMark?: string;
  size?: number;
}
export function QuestionMark(props: QuestionMarkProps) {
  const { textColorQuestionMark = 'text-light', text, size = 1.2, ...rest } = props;

  if (text === null || (typeof text === 'string' && text === '')) return null;

  return (
    <TooltipWarning text={text} {...rest}>
      <div className={'position-relative d-flex'}>
        <HelpCircleOutlineIcon size={size} className={textColorQuestionMark} />
      </div>
    </TooltipWarning>
  );
}
