import { PositionType } from './position';
import { StaffType } from './staff';
import { PayrollType, SiteType, UserInfoType } from '@types';
import type { RosterShiftSchema } from 'components/roster/schema';
import type { ProcessedShiftsWarnings, TotalSummarized } from 'components/roster/store';

interface Rate {
  shiftId: number;
  shiftHours: number;
  overtimeHours: number;
  shiftBreak: number;
  shiftAvgCost: number;
  shiftCost: number;
}
export enum EVENT {
  SICK = 'SICK',
  CLOCK_IN = 'CLOCK_IN',
  CLOCK_OUT = 'CLOCK_OUT',
  BREAK_START = 'BREAK_START',
  BREAK_END = 'BREAK_END'
}
export interface EventType {
  id: number;
  event: EVENT;
  description: string;
  eventDate: CustomDate;
  photo: string;
  latitude: string;
  longitude: string;
  /** front-end only */
  label: string;
}
export interface PayrollNote {
  author: string;
  message: string;
}
export type ShiftType = {
  clockInfo: EventType[] | [];
  finishDate: CustomDate;
  id: number;
  // isEmergency: boolean;
  isSick: boolean;
  isNominated: boolean;
  manager?: Pick<StaffType, 'avatar' | 'email' | 'employmentType' | 'firstName' | 'id' | 'lastName'>;
  message: PayrollNote[] | [];
  rosterMessage: string;
  rosterShiftNote: string;
  shiftMessages: PayrollNote[] | [];
  position: PositionType;
  rosterId: number;
  rosterName: string;
  shiftSwap: ShiftSwap;
  site: SiteType;
  staff: StaffType;
  startDate: CustomDate;
  swapStatusChangedDate: CustomDate;
  timesheet: Timesheet;
  customBreak: CustomBreak | null;
  rate: Rate;
  minEngagement?: number;
  hasMinEngagement?: boolean;
  hasWeeklyOvertimeRules?: boolean;
  hasExtraHours?: boolean;
  hasOvertime?: boolean;
  hasMessages?: boolean;
  hasWeeklyOvertime?: boolean;
  totalOvertimeDaily?: number;
  totalWeeklyOvertime?: number;
  totalRosterHours: number /** front-end only **/;
  totalTimeClockHours: number /** front-end only **/;
  totalTimeClockBreakMin: number /** front-end only **/;
  variance: number /** front-end only **/;
  excludeRates: number[];
  includeRates: number[];
  shiftCandidates: unknown[];
};

type ProcessedSingleShiftWarnings = {
  dailyOvertime: boolean;
  dailyOvertimeHours: number;
  extraHours: boolean;
  overMaxHours: boolean;
  overlaps: number[];
  rosteredAnotherSite: boolean;
  shiftSwap: boolean;
  weeklyOvertime: boolean;
  weeklyOvertimeHours: number;
  unavailableStaff: boolean;
  minEngagementHours: number;
  allowances?: Record<'ALLOWANCES-DAILY' | 'ALLOWANCES-WEEKLY' | 'ALLOWANCES-HOURLY' | 'ALLOWANCES-MEAL', number>;
};

export type RosterShiftType = RosterShiftSchema & {
  originalVirtualShift: {
    finishDate?: string | null;
    finishTime?: string | null;
    startDate?: string | null;
    startTime?: string | null;
    staffId?: number | null;
  };
  staff?: Pick<StaffType, 'id' | 'lastName' | 'firstName' | 'status'>;
  site?: SiteType;
  position?: PositionType;
  processed?: TotalSummarized & {
    warnings: ProcessedSingleShiftWarnings;
  };
  oldSiteId?: number | null;
  oldPositionId?: number | null;
  oldStaffId?: number | null | 'open';
  shiftType?: 'FROM_ROSTER' | 'FROM_PAYROLL' | 'EMERGENCY' | 'UNSCHEDULED';
  clockInfo?: PayrollType['rosterMember'][0]['clockInfo'] | null;
  isSick?: boolean;
};

export type CustomBreak = {
  breakHours: number;
  startTime: string;
  type: string;
};

export enum SHIFT_RANGE_PERIOD_TYPE {
  ROSTERED = 'rostered',
  ACTUAL = 'actual',
  ROUNDED = 'rounded',
  CUSTOM = 'custom'
}
type Timesheet = {
  customBreak: number | null;
  customFinishDate: CustomDate;
  customStartDate: CustomDate;
  isActive: boolean;
  selectedOption: SHIFT_RANGE_PERIOD_TYPE;
};

export enum SHIFT_SWAP_STATUS {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED'
}
export type ShiftSwap = {
  message: string | null;
  originalStaff: Pick<UserInfoType, 'avatar' | 'email' | 'firstName' | 'lastName'> & { id: number };
  status: SHIFT_SWAP_STATUS;
  swappedStaff: ShiftSwap['originalStaff'];
  staffOriginal: ShiftSwap['originalStaff'];
  staffSwapped: ShiftSwap['originalStaff'];
};

export type CustomDate = {
  date: string;
  timezone_type: number;
  timezone: string;
};
export type RosterWarningType = {
  value: keyof ProcessedShiftsWarnings;
  label: string;
};
