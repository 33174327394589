import { SessionTitle } from 'components/session-title';
import { CustomHelmet } from 'components/custom-helmet';
import { CheckboxMarkedCircleIcon, CloseCircleIcon, MapMarkerRadiusIcon } from 'components/icons';
import React, { useState } from 'react';
import style from './style.module.scss';
import Loader, { useGlobalLoader } from 'components/loader';
import ResourceLink from 'components/resource-link';
import { SessionHelpBar } from 'components/session-help-bar';
import { createModalLink, useModal } from 'components/modal';
import { useToast, useToastError } from 'components/toasts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { produce } from 'immer';
import { allowUser } from 'components/allow-user';
import { PERMISSION, SiteType } from '@types';
import { RequestError } from 'components/error/request-error';
import { useUser } from 'hooks/api/useUser';
import { useSitesAll } from 'hooks/api/useSitesAll';
import { TooltipCpanel } from 'components/tooltip-cpanel';

export default function Sites() {
  const [status, setStatus] = useState<SiteType['status']>('ACTIVE');
  const [search, setSearch] = useState('');
  const { data: allSites, isFetching, error } = useSitesAll();
  const sites = allSites?.filter(site => site.status === status && (search !== '' ? site.name.toLowerCase().includes(search.toLowerCase()) : true));
  const { data: userData } = useUser();

  const { open, hide } = useModal();
  const { showGlobalLoader, hideGlobalLoader } = useGlobalLoader();
  const { addToast } = useToast();
  const onError = useToastError();
  const queryClient = useQueryClient();
  const { mutate: remove } = useMutation({
    mutationFn: (payload: SiteType) => axios.post('/sites/remove', { id: payload.id }),
    onSuccess(data, payload) {
      queryClient.setQueryData<SiteType[]>(
        ['/sites/all'],
        produce(draft => {
          if (draft) {
            const index = draft.findIndex(p => p.id === payload.id);
            if (index !== -1) draft[index].status = 'INACTIVE';
          }
        })
      );

      addToast({
        title: 'Success!',
        body: `${payload.name} has been deleted.`
      });
    },
    onMutate() {
      showGlobalLoader();
    },
    onSettled() {
      hideGlobalLoader();
    },
    onError
  });
  const { mutate: reactivate } = useMutation({
    mutationFn: (payload: SiteType) => axios.post('/sites/reactive', { id: payload.id }),
    onSuccess(data, payload) {
      queryClient.setQueryData<SiteType[]>(
        ['/sites/all'],
        produce(draft => {
          if (draft) {
            const index = draft.findIndex(p => p.id === payload.id);
            if (index !== -1) draft[index].status = 'ACTIVE';
          }
        })
      );

      addToast({
        title: 'Success!',
        body: `${payload.name} has been reactivated.`
      });
    },
    onMutate() {
      showGlobalLoader();
    },
    onSettled() {
      hideGlobalLoader();
    },
    onError
  });

  if (isFetching) return <Loader fullscreen />;
  else if (error) return <RequestError error={error} />;

  const onClickToggleStatus = (site: SiteType) => {
    if (site.status === 'ACTIVE') {
      open('decision', {
        size: 'sm',
        props: {
          title: 'Deactivate Site?',
          body: (
            <div className={'d-flex flex-column gap-3 align-items-start justify-content-center h-100'}>
              <p>
                {site.hasFutureRosters
                  ? 'This site has future rostered shifts. If deactivated, all associated shifts will be removed from the roster. You can reactivate the site later to restore these shifts. Are you sure you want to proceed?'
                  : 'Deactivating this site will prevent new shifts from being added. You can reactivate the site later if needed. Are you sure you want to proceed?'}
              </p>
              <div className={'d-flex align-items-start h-100 gap-3 justify-content-center'}>
                <MapMarkerRadiusIcon />
                {site.name}
              </div>
            </div>
          ),
          textOk: 'DEACTIVATE',
          onClick: () => {
            hide();
            remove(site);
          },
          isError: true
        }
      });
    } else {
      open('decision', {
        size: 'sm',
        props: {
          title: 'Reactivate Site?',
          body: (
            <div className={'d-flex flex-column gap-3 align-items-start justify-content-center h-100'}>
              <p>Please confirm below to reactivate this site.</p>
              <div className={'d-flex align-items-start gap-3'}>
                <MapMarkerRadiusIcon />
                {site.name}
              </div>
            </div>
          ),
          textOk: 'REACTIVATE',
          onClick: () => {
            hide();
            reactivate(site);
          }
        }
      });
    }
  };

  const onClickAddButton = () => {
    queryClient.removeQueries({ queryKey: ['/sites/0'] });
    open('add-site');
  };

  return (
    <section>
      <div className={'d-flex align-items-center justify-content-between'}>
        <SessionTitle
          title={'SITES'}
          icon={<MapMarkerRadiusIcon size={2} />}
          questionMarkText={
            <span>
              Sites can be either different locations or different departments of a single business.
              <br />
              <TooltipCpanel alias={'sites'} />
            </span>
          }
          onClickAddButton={allowUser(userData?.userInfo, [PERMISSION.ADMIN, PERMISSION.ACCOUNTANT, PERMISSION.MANAGER]) ? onClickAddButton : undefined}
        />

        <SessionHelpBar title="Sites" currentStatus={status} setStatus={setStatus} setSearch={setSearch} />
      </div>
      <hr />
      <div className={`site-list ${style.grid}`}>
        {sites?.map((site, index) => (
          <ResourceLink minHeight={50} delayFadeIn={index * 50} key={site.id} className={style.site} to={createModalLink('add-site', { siteId: site.id })}>
            <div className={'d-flex align-items-center justify-content-between w-100 text-gray-7'}>
              <div className={`d-flex align-items-center gap-2`}>
                <MapMarkerRadiusIcon size={1.4} />
                <p className={'border-0 rounded ps-2'}>{site.name}</p>
              </div>
              <span
                className={`toggle delete ${site.status === 'ACTIVE' ? 'text-danger' : 'text-success'}`}
                onClick={e => {
                  e.preventDefault();
                  onClickToggleStatus(site);
                }}
              >
                {site.status === 'ACTIVE' ? <CloseCircleIcon /> : <CheckboxMarkedCircleIcon />}
              </span>
            </div>
          </ResourceLink>
        ))}
      </div>
      <CustomHelmet subtitle={'Sites'} />
    </section>
  );
}
