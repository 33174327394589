import { useAppConfig } from 'hooks/api/useAppConfig';
import { renderByDomain } from 'helpers/which-domain';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { openGleenAI } from 'helpers/openGleenAI';
import { TooltipCpanelAlias } from '@types';

type TooltipCpanelProps = {
  alias: TooltipCpanelAlias;
  text?: string;
  color?: string;
  anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
};
export function TooltipCpanel(props: TooltipCpanelProps) {
  const { alias, anchorProps, buttonProps, color = 'text-white', text = 'Read More' } = props;

  const { data } = useAppConfig();
  const chat = data?.result.config.chat;
  const tooltips = data?.result.config.tooltips;

  let content;
  if (tooltips) {
    if (chat === 'gleen') {
      const gleenMessage = tooltips[alias].gleenMessage;

      content = (
        <button type={'button'} className={`text-decoration-underline ${color}`} onClick={() => openGleenAI(gleenMessage)} {...buttonProps}>
          {text}
        </button>
      );
    } else {
      const elfLink = tooltips[alias]?.rosterElfLink || '#';
      const clubsLink = tooltips[alias]?.clubsHRLink || '#';

      content = (
        <a href={renderByDomain(elfLink, clubsLink)} target={'_blank'} rel={'noreferrer'} className={color} {...anchorProps}>
          {text}
        </a>
      );
    }
  }

  return content;
}
